import React from "react"
import { SupportDialog } from "./SupportDialog"
import IconButton from "@mui/material/IconButton"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import { TopBarTooltip } from "layout/MainLayout/TopBar/TopBarTooltip"

export const Support = () => {
    const [open, setOpen] = React.useState(false)

    return (
        <>
            <TopBarTooltip title={"Help Center & Support"}>
                <IconButton color="inherit" onClick={() => setOpen(true)}>
                    <HelpOutlineOutlinedIcon />
                </IconButton>
            </TopBarTooltip>
            <SupportDialog open={open} onClose={() => setOpen(false)} />
        </>
    )
}
