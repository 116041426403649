import Badge from "@mui/material/Badge"
import { IconButton } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "shared/redux/store"
import { notificationActions } from "domain/notification/redux/load.notification.actions"
import { Notification } from "domain/notification/redux/notification.slice"
import { NotificationType } from "domain/notification/NotificationService"
import { useNotificationContext } from "layout/MainLayout/UserMenu/Notification/NotificationContext"
import GiftIcon from "shared/component/icons/GiftIcon"
import { TopBarTooltip } from "layout/MainLayout/TopBar/TopBarTooltip"

export const NewsNotification = (): JSX.Element => {
    const notificationContext = useNotificationContext()
    const dispatch = useAppDispatch()
    const notificationState = useSelector((state: RootState) => state.notifications)
    const newsNotification: Notification = notificationState.notifications.find(
        (notification: Notification) => notification.type == NotificationType.NEWS,
    )
    const onClick = () => {
        notificationActions.dismissNotification(NotificationType.NEWS)(dispatch)
        notificationContext.setShowNews(true)
    }
    return (
        <TopBarTooltip title={"What's New?"}>
            <IconButton onClick={onClick}>
                <Badge badgeContent={newsNotification?.count ?? 0} color="primary" variant={"standard"}>
                    <GiftIcon fontSize={"medium"} />
                </Badge>
            </IconButton>
        </TopBarTooltip>
    )
}
