/* eslint-disable */

import { log } from "shared/analytics/GainsightAnalyticsService"

export const insertAptrinsicTag = (gainsightProductKey) => {
    ;(function (n, t, a, e) {
        var i = "aptrinsic"

        n[i] =
            n[i] ||
            function () {
                ;(n[i].q = n[i].q || []).push(arguments)
            }

        n[i].p = e

        var r = t.createElement("script")
        r.async = false
        r.src = a + "?a=" + e
        r.onload = function () {
            log("Aptrinsic tag loaded")
        }

        var c = t.getElementsByTagName("script")[0]
        c.parentNode.insertBefore(r, c)
    })(window, document, "https://web-sdk.aptrinsic.com/api/aptrinsic.js", gainsightProductKey)
}
