import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import DialogActions from "@mui/material/DialogActions"
import Grid from "@mui/material/Unstable_Grid2"
import { LayoutElementType, LayoutMode, ModalConfig, RowLayoutConfigDTO } from "domain/types"
import { ContainerElementDTO } from "generated/models"
import React, { useContext, useEffect } from "react"
import { AdditionalFilterContext } from "shared/component/layout/context/AdditionalFilterContext"
import { FooterState, useRootElementContext } from "shared/component/layout/context/RootElementContext"
import LayoutRenderer from "shared/component/layout/renderers/LayoutRenderer"
import { CustomDialog } from "layout/components/CustomDialog"
import { styled } from "@mui/material/styles"

type CustomModalProps = {
    modal: ModalConfig
    index: number
}

const StyledCustomDialog = styled(CustomDialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        // position relative is needed for the spinner
        position: "relative",
    },
}))

export const getDefaultFooter = (modal: ModalConfig): JSX.Element => {
    const additionalFooterElementsContainer = {
        elementType: LayoutElementType.CONTAINER,
        layoutConfig: { layoutMode: LayoutMode.ROWS } as RowLayoutConfigDTO,
        children: modal.additionalFooterElements,
    } as ContainerElementDTO
    return (
        <Grid container>
            <Grid xs={6} className={"additional-footer-elements"} sx={{ pl: "32px", pt: "32px" }}>
                <LayoutRenderer layoutElementConfig={additionalFooterElementsContainer} />
            </Grid>
            <Grid xs={6}>
                <DialogActions>
                    <Button color="info" key="back" variant="text" size="large" onClick={modal.onCancel}>
                        {modal.cancelText || "Cancel"}
                    </Button>
                    <Button
                        color="info"
                        key="submit"
                        variant="contained"
                        size="large"
                        onClick={modal.onOk}
                        disabled={modal.contentLoading}
                    >
                        {modal.okText || "Save"}
                    </Button>
                </DialogActions>
            </Grid>
        </Grid>
    )
}

/**
 * Created the footer, including any wrapper elements, to be rendered
 * @param footer
 * @param defaultFooter
 */
const getFooterToRender = (footer, defaultFooter): JSX.Element => {
    if (footer == FooterState.HIDE_FOOTER) {
        // if footer should be hidden, we just show the default footer; the elements will be hidden via CSS later
        // this way, the footer remains the same height and we can easily fade it in and out via CSS
        return defaultFooter
    } else if (footer == FooterState.SHOW_DEFAULT_FOOTER) {
        return defaultFooter
    } else {
        return footer
    }
}

const CustomModal: React.FC<CustomModalProps> = (props: CustomModalProps): JSX.Element => {
    const { footer, defaultFooter, updateDefaultFooter } = useRootElementContext()
    const additionalFilterContext = useContext(AdditionalFilterContext)
    const { modal, index } = props

    const footerToRender = getFooterToRender(footer, defaultFooter)

    useEffect(() => {
        const defaultFooter = getDefaultFooter(props.modal)
        updateDefaultFooter(defaultFooter)

        additionalFilterContext.updateAdditionalFilters(props.modal.additionalFilters)
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.modal])

    const dialogClassNames = ["custom-modal", modal.identifier]
    if (footer == FooterState.HIDE_FOOTER) {
        dialogClassNames.push("no-footer")
    }

    const header = (
        <>
            {modal.contentLoading ? "Loading..." : modal.title || ""}
            {modal.subtitle}
        </>
    )

    const content = (
        <div className={"spinner-wrapper"}>
            <div
                style={{
                    position: "absolute",
                    zIndex: 10,
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                className={modal.contentLoading ? "fade-in-spinner" : "fade-out-spinner"}
            >
                <CircularProgress />
            </div>
            <div className={"content-wrapper"}>{modal.content}</div>
        </div>
    )

    return (
        <>
            <StyledCustomDialog
                className={dialogClassNames.join(" ")}
                open={modal.visible}
                key={`__modal__${index}`}
                onClose={modal.onCancel}
                footer={{ kind: "custom", content: footerToRender }}
                content={content}
                header={header}
                modalMinHeight={modal.modalMinHeight ?? 700}
                modalWidth={modal.modalWidth ?? 700}
            />
        </>
    )
}
export default CustomModal
