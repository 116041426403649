import React, { Fragment } from "react"
import { Box, Drawer } from "@mui/material"
import PerfectScrollbar from "react-perfect-scrollbar"
import Menu from "layout/MainLayout/Menu/Menu"
import FloatingMenu from "layout/MainLayout/FloatingMenu/FloatingMenu"
import {
    DRAWER_WIDTH_FLOATING_MODE,
    DRAWER_WIDTH_LARGE_SCREEN,
    TOP_BAR_HEIGHT_LARGE_SCREEN,
    TOP_BAR_HEIGHT_SMALL_SCREEN,
} from "layout/MainLayout/constants"
import { styled } from "@mui/material/styles"
import { useDrawerContext } from "layout/MainLayout/Drawer/DrawerContext"

export const SideBar = () => {
    const { isDrawerOpen } = useDrawerContext()

    const CloseDrawerStyledBox = styled(Box)(({ theme }) => ({
        width: DRAWER_WIDTH_FLOATING_MODE,
        "& .MuiDrawer-root": {
            width: DRAWER_WIDTH_FLOATING_MODE,
        },
        "& .MuiDrawer-paper": {
            overflow: "hidden",
            marginTop: TOP_BAR_HEIGHT_LARGE_SCREEN,
            width: DRAWER_WIDTH_FLOATING_MODE,
            [theme.breakpoints.down("lg")]: {
                marginTop: TOP_BAR_HEIGHT_SMALL_SCREEN,
            },
            zIndex: 1099,
        },
    }))

    return (
        <Fragment>
            {isDrawerOpen && (
                <OpenDrawerStyledBox component="nav">
                    <Drawer
                        variant="permanent"
                        anchor="left"
                        open={isDrawerOpen}
                        ModalProps={{ keepMounted: true }}
                        color="inherit"
                    >
                        <PerfectScrollbar component="div">
                            <Menu />
                        </PerfectScrollbar>
                    </Drawer>
                </OpenDrawerStyledBox>
            )}
            {!isDrawerOpen && (
                <CloseDrawerStyledBox component="nav">
                    <Drawer
                        variant="permanent"
                        anchor="left"
                        open={isDrawerOpen}
                        ModalProps={{ keepMounted: true }}
                        color="inherit"
                    >
                        <FloatingMenu />
                    </Drawer>
                </CloseDrawerStyledBox>
            )}
        </Fragment>
    )
}

const OpenDrawerStyledBox = styled(Box)(({ theme }) => ({
    width: DRAWER_WIDTH_LARGE_SCREEN,
    "& .MuiDrawer-root": {
        width: DRAWER_WIDTH_LARGE_SCREEN,
    },
    "& .MuiDrawer-paper": {
        overflow: "hidden",
        marginTop: TOP_BAR_HEIGHT_LARGE_SCREEN,
        width: DRAWER_WIDTH_LARGE_SCREEN,
        [theme.breakpoints.down("lg")]: {
            marginTop: TOP_BAR_HEIGHT_SMALL_SCREEN,
        },
        zIndex: 1099,
    },
}))

export default SideBar
