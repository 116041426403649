import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    useTheme,
    Box,
    Divider,
    Fade,
} from "@mui/material"
import { MenuDTO, MenuNodeDTO } from "generated/models"
import { assertExhaustive } from "shared/util/TypeUtil"
import { MenuIcon } from "layout/MainLayout/FloatingMenu/MenuIcon"
import { IconChevronRight } from "@tabler/icons"
import { MenuLeafDTO } from "domain/types"
import { isMenuLeaf, isNode } from "shared/service/MenuUtil"
import { useLayoutContext } from "layout/MainLayout/LayoutContext"
import { FONT_WEIGHT_BOLD, FONT_WEIGHT_REGULAR } from "styles/theme/constants"
import { styled } from "@mui/material/styles"
import { debounce } from "throttle-debounce"

export interface NodeListItemProps {
    type: "node"
    menuNodeDTO: MenuNodeDTO
    level: number
}

export interface LeafListItemProps {
    type: "leaf"
    menuLeafDTO: MenuLeafDTO
    level: number
    isSelected: boolean
    onClick: (event: React.MouseEvent) => void
}

export type MenuListItemProps = NodeListItemProps | LeafListItemProps

export const FloatingMenuListItem = (props: MenuListItemProps) => {
    switch (props.type) {
        case "node": {
            return <NodeListItem {...props} />
        }
        case "leaf": {
            return <LeafListItem {...props} />
        }
        default: {
            assertExhaustive(props)
        }
    }
}

function collapseIcon(level: number): React.ReactNode {
    if (level > 0) {
        return (
            <div
                style={{
                    height: "16px",
                    width: "16px",
                    transition: "all 0.2s ease-in-out",
                    marginTop: "-4px",
                    transform: "rotate(0deg)",
                }}
            >
                <IconChevronRight stroke={1.5} size="16px" />
            </div>
        )
    } else {
        return null
    }
}

const isZeroLevel = (level: number) => level === 0

const NodeListItem = ({ menuNodeDTO, level }: NodeListItemProps) => {
    const { title, path, icon } = menuNodeDTO

    const theme = useTheme()
    const { navigate, pathname } = useLayoutContext()

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const debouncedAnchorEl = useDebouncedValue(anchorEl, 400)
    const elementHovered = Boolean(debouncedAnchorEl)

    const onMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event?.currentTarget)
    }

    const onMouseLeave = () => {
        setAnchorEl(null)
    }

    const renderMenuDTO = (level: number, item: MenuDTO) => {
        if (isMenuLeaf(item)) {
            return renderLeaf(level, item)
        } else if (isNode(item)) {
            return renderNode(level, item)
        }
    }

    const makeLeafClickHandler = (path: string) => (event: React.MouseEvent) => {
        // prevent the default a href navigation behaviour
        event.preventDefault()
        navigate(path)
    }

    const renderLeaf = (level: number, item: MenuLeafDTO) => {
        return (
            <FloatingMenuListItem
                key={item.title}
                type="leaf"
                menuLeafDTO={item}
                level={level}
                isSelected={item.path === pathname}
                onClick={makeLeafClickHandler(item.path)}
            />
        )
    }

    const renderNode = (level: number, item: MenuNodeDTO) => {
        return <FloatingMenuListItem key={item.title} type="node" menuNodeDTO={item} level={level} />
    }

    return useMemo(
        () => (
            <ListItem
                key={path + "_" + level + "_ListItem"}
                className={"menu-list-item menu-list-item-node level-" + level}
                disablePadding
            >
                <ListItemButton
                    selected={pathname.indexOf(path) >= 0}
                    disableRipple={true}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    sx={getItemSx(level)}
                >
                    {icon && (
                        <ListItemIcon>
                            <MenuIcon icon={menuNodeDTO.icon} />
                        </ListItemIcon>
                    )}
                    <ListItemText
                        primary={title}
                        sx={[
                            {
                                opacity: level > 0 ? 1 : 0,
                            },
                        ]}
                    />
                    {collapseIcon(level)}
                    <Popper
                        open={elementHovered}
                        anchorEl={debouncedAnchorEl}
                        transition
                        placement="right-start"
                        style={{
                            zIndex: 2001,
                        }}
                        modifiers={[
                            {
                                name: "offset",
                                options: {
                                    offset: [-12, -5],
                                },
                            },
                        ]}
                    >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={200}>
                                <StyledPaper
                                    key={path + "_" + level + "_StyledPaper"}
                                    sx={{
                                        overflow: "hidden",
                                        mt: 1.5,
                                        boxShadow: theme.shadows[8],
                                        backgroundImage: "none",
                                    }}
                                >
                                    <Box sx={{ minWidth: "150px" }}>
                                        {isZeroLevel(level) && (
                                            <Fragment>
                                                <ListItemText
                                                    primary={title}
                                                    sx={[
                                                        {
                                                            paddingLeft: "20px",
                                                            paddingRight: "20px",
                                                            paddingTop: "5px",
                                                            paddingBottom: "5px",
                                                        },
                                                    ]}
                                                    primaryTypographyProps={{ variant: "subtitle2" }}
                                                />
                                                <Divider />
                                            </Fragment>
                                        )}
                                        {menuNodeDTO.items.map((item) => renderMenuDTO(level + 1, item))}
                                    </Box>
                                </StyledPaper>
                            </Fade>
                        )}
                    </Popper>
                </ListItemButton>
            </ListItem>
        ),
        [debouncedAnchorEl],
    )
}

const LeafListItem = ({ menuLeafDTO, level, isSelected, onClick }: LeafListItemProps) => {
    const { title, path } = menuLeafDTO

    return menuLeafDTO.hidden ? (
        <div key={path} />
    ) : (
        <ListItem
            key={path}
            disablePadding
            className={"menu-list-item menu-list-item-leaf" + (isSelected ? " selected" : "") + " level-" + level}
        >
            <ListItemButton
                selected={isSelected}
                component={"a"}
                href={path}
                disableRipple={true}
                onClick={onClick}
                sx={getItemSx(level)}
            >
                <ListItemText primary={title} sx={[{ opacity: level > 0 ? 1 : 0 }]} />
            </ListItemButton>
        </ListItem>
    )
}

const getItemSx = (level: number) => {
    return {
        paddingLeft: isZeroLevel(level) ? "15px" : "20px",
        height: "40px",
        ":hover": {
            backgroundColor: "#e6ecfc",
            color: "black",
        },
    }
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    "& .MuiListItemButton-root:hover": {
        color: "black",
        fontWeight: FONT_WEIGHT_REGULAR,
    },
    "& .Mui-selected": {
        backgroundColor: `${theme.palette.primaryShades[50]} !important`,
        color: `${theme.palette.primary.main} !important`,
        fontWeight: `${FONT_WEIGHT_BOLD} !important`,
    },
}))

const useDebouncedValue = (inputValue: any, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(inputValue)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(inputValue)
        }, delay)

        return () => {
            clearTimeout(handler)
        }
    }, [inputValue, delay])

    return debouncedValue
}
