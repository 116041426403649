import React, { Fragment, useMemo } from "react"
import { List, Paper, useTheme } from "@mui/material"
import { MenuDTO, MenuNodeDTO, MenuRootDTO } from "generated/models"
import { FloatingMenuListItem } from "layout/MainLayout/FloatingMenu/FloatingMenuListItem"
import { useLayoutContext } from "layout/MainLayout/LayoutContext"
import { useAnalytics } from "shared/analytics/AnalyticsContext"
import { log } from "shared/util/log"
import { styled } from "@mui/material/styles"
import { MAIN_PADDING_LARGE_SCREEN, MAIN_PADDING_SMALL_SCREEN } from "layout/MainLayout/constants"
import {
    FONT_WEIGHT_BOLD,
    FONT_WEIGHT_MEDIUM,
    FONT_WEIGHT_REGULAR,
    FONT_WEIGHT_SEMI_BOLD,
} from "styles/theme/constants"
import { isMenuLeaf, isNode } from "shared/service/MenuUtil"
import { MenuLeafDTO } from "domain/types"

export const FloatingMenu = () => {
    const theme = useTheme()
    const { userConfigs } = useLayoutContext()
    const menu = useMemo(() => userConfigs.menu ?? { root: [] }, [userConfigs])

    const { navigate, pathname } = useLayoutContext()

    const analyticsService = useAnalytics()

    const makeLeafClickHandler = (path: string) => (event: React.MouseEvent) => {
        // prevent the default a href navigation behaviour
        event.preventDefault()

        navigate(path)

        const printablePath = findPrintablePathInTree(path, menu.root)
        analyticsService
            .trackMenuNavigation(printablePath[0], printablePath.join(" / "))
            .catch((err) => log.debug("Failed to track menu navigation", err))
    }

    const renderMenuElements = (menuItems: MenuRootDTO) => {
        return (
            <StyledPaper elevation={0} className={"main-menu"}>
                {renderMenuElementsRec(0, menuItems.root)}
            </StyledPaper>
        )
    }

    const renderMenuElementsRec = (level: number, menuItems: MenuDTO[], dense = false) => {
        return (
            <List
                dense={dense}
                className={"menu-list level-" + level}
                sx={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    position: level == 1 ? "relative" : "inherit",
                    "&:after":
                        level > 0
                            ? {
                                  content: "''",
                                  position: "absolute",
                                  left: "25px",
                                  top: 0,
                                  height: "100%",
                                  width: "1px",
                                  background: theme.palette.border.subtle,
                              }
                            : {},
                }}
            >
                {menuItems.map((item) => renderMenuDTO(level, item))}
                {level == 1 && (
                    <div
                        className={"selected-leaf-indicator"}
                        style={{
                            borderRadius: "5px",
                            zIndex: 1,
                            content: "''",
                            position: "absolute",
                            left: "23px",
                            top: "0",
                            height: "0px",
                            width: "5px",
                            background: theme.palette.primary.main,
                            transition: "all 0.5s ease-in-out",
                        }}
                    ></div>
                )}
            </List>
        )
    }

    const renderMenuDTO = (level: number, item: MenuDTO) => {
        if (isMenuLeaf(item)) {
            return renderLeaf(level, item)
        } else if (isNode(item)) {
            return renderNode(level, item)
        }
    }

    const renderLeaf = (level: number, item: MenuLeafDTO) => {
        return (
            <FloatingMenuListItem
                key={item.title}
                type="leaf"
                menuLeafDTO={item}
                level={level}
                isSelected={pathname.indexOf(item.path) >= 0}
                onClick={makeLeafClickHandler(item.path)}
            />
        )
    }

    const renderNode = (level: number, item: MenuNodeDTO) => {
        return (
            <Fragment key={item.path + "_node"}>
                <FloatingMenuListItem key={item.title} type="node" menuNodeDTO={item} level={level} />
            </Fragment>
        )
    }

    return renderMenuElements(menu)
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: MAIN_PADDING_LARGE_SCREEN,
    paddingTop: "20px",
    [theme.breakpoints.down("lg")]: {
        padding: MAIN_PADDING_SMALL_SCREEN,
    },
    "& .MuiListItem-root": {
        padding: 0,
    },
    "& > .MuiList-root > .MuiListItem-root:first-of-type": {
        marginTop: "-15px",
        paddingTop: 0,
        [theme.breakpoints.down("lg")]: {
            marginTop: "-10px",
        },
    },
    "& .MuiListItemButton-root": {
        lineHeight: "150%",
        paddingRight: "5px",
        "&:hover": {
            backgroundColor: "#e6ecfc",
            fontWeight: FONT_WEIGHT_SEMI_BOLD,
            transition: "all 0.1s ease-in-out",
        },
    },
    "& .level-0 .MuiListItemButton-root": {
        paddingBottom: "2px",
        paddingTop: "4px",
        fontWeight: FONT_WEIGHT_MEDIUM,
        [theme.breakpoints.down("lg")]: {
            paddingBottom: "6px",
            paddingTop: "4px",
        },
    },
    "& .level-0 span": {
        color: "rgba(54, 72, 125, 0.95)",
        fontWeight: FONT_WEIGHT_BOLD,
    },
    "& .level-1 span": {
        fontWeight: FONT_WEIGHT_MEDIUM,
    },
    "& *": {
        fontSize: "13px!important",
    },
    "& .level-1 .MuiListItemButton-root": {
        marginLeft: "0px",
        paddingBottom: "1px",
        paddingTop: "1px",
        fontWeight: FONT_WEIGHT_REGULAR,
        [theme.breakpoints.down("lg")]: {
            paddingBottom: "0px",
            paddingTop: "0px",
        },
    },
    "& .MuiSvgIcon-root": {
        color: "rgba(54, 72, 125, 0.8)",
        height: "20px",
        marginTop: "-1px",
        width: "20px",
    },
    "& .MuiListItemIcon-root": {
        minWidth: "28px",
    },
    "& .selected > .MuiListItemButton-root": {
        marginRight: "8px",
        marginLeft: "0px",
        backgroundColor: theme.palette.primaryShades[50],
        borderRadius: "5px",
    },
    "& .selected span": {
        color: theme.palette.primary.main,
        fontWeight: FONT_WEIGHT_BOLD,
    },
    "& .Mui-selected": {
        backgroundColor: theme.palette.primaryShades[50],
    },
}))

const findPrintablePathInTree = (path: string, tree: MenuDTO[]): string[] => {
    for (const item of tree) {
        if (isMenuLeaf(item)) {
            if (item.path === path) {
                return [item.title]
            }
        } else if (isNode(item)) {
            const result = findPrintablePathInTree(path, item.items)
            if (result.length > 0) {
                return [item.title, ...result]
            }
        }
    }

    return []
}

export default FloatingMenu
