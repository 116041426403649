import React from "react"
import { useSelector } from "react-redux"
import { insertAptrinsicTag } from "shared/analytics/AptrinsicTag"
import { RootState } from "shared/redux/store"
import { log as logUtil } from "shared/util/log"
import { AnalyticsContext } from "./AnalyticsContext"
import { AnalyticsService, noopAnalyticsService } from "./AnalyticsService"
import { useUiConfigQuery } from "shared/uiconfig/useUiConfigQuery"

const IGNORED_USERS: readonly string[] = ["OTTO-SLA-Login-Test"]

export interface GainsightAnalyticsContextProviderProps {
    children: React.ReactNode
}

export const GainsightAnalyticsContextProvider = ({ children }: GainsightAnalyticsContextProviderProps) => {
    // Step 1: Is the user logged in via first factor? We don't want to initialize Gainsight before that.
    const loggedInViaFirstFactor = useSelector((state: RootState) => state.authentication.loggedInViaFirstFactor)
    const skipUiConfigQuery = !loggedInViaFirstFactor

    // Step 2: Get the Gainsight config from the backend if the user is logged in. If Gainsight is disabled in the config, we don't need to initialize it.
    const uiConfig = useUiConfigQuery({ skip: skipUiConfigQuery })

    const userInfo = useSelector((state: RootState) => state.user?.userInfo)
    const appContext = useSelector((state: RootState) => state.appContext?.appContext)

    const [initializationRequested, setInitializationRequested] = React.useState<
        false | "withLoginEvent" | "withoutLoginEvent"
    >(false)
    const [dispatchIdentifyEvent, setDispatchIdentifyEvent] = React.useState(false)
    const [dispatchLoginEvent, setDispatchLoginEvent] = React.useState(false)

    // Only proceed if we have all the data we need
    if (!uiConfig.isSuccess || !userInfo || !appContext) {
        log("Not all data available, not initializing")
        return (
            // We don't know yet if Gainsight is enabled, but calls to initialize() might already be made, so we need to make sure we don't lose them.
            <AnalyticsContext.Provider
                value={{
                    async initialize(trackLoginEvent: boolean) {
                        setInitializationRequested(trackLoginEvent ? "withLoginEvent" : "withoutLoginEvent")
                    },
                    async trackMenuNavigation(rootNode: string, path: string) {
                        return
                    },
                }}
            >
                {children}
            </AnalyticsContext.Provider>
        )
    }

    // If Gainsight is disabled, we provide a dummy AnalyticsService that does nothing
    if (!uiConfig.data.gainsightEnabled) {
        log("Gainsight is disabled, not initializing")
        return <AnalyticsContext.Provider value={noopAnalyticsService}>{children}</AnalyticsContext.Provider>
    }

    // Step 3: Initialize Gainsight
    log("All data available, proceeding")
    const {
        gainsightProductKey,
        gainsightAppendNumericId,
        gainsightIdPrefix,
        gainsightIsProduction,
        gainsightTrackInternalUsers,
    } = uiConfig.data

    const isIgnoredUserName = IGNORED_USERS.includes(userInfo.loginName)
    const isIgnoredUser = isIgnoredUserName || (!gainsightTrackInternalUsers && userInfo.internalUser)

    const initialize = async (trackLoginEvent = false) => {
        if (isInitialized()) {
            log("Already initialized, not initializing again")
            return
        }

        if (isIgnoredUser) {
            log("User is ignored, not initializing")
            return
        }

        log("Inserting Aptrinsic tag")
        insertAptrinsicTag(gainsightProductKey)

        setDispatchIdentifyEvent(true)
        if (trackLoginEvent) {
            setDispatchLoginEvent(true)
        }
    }

    const isInitialized = () => Boolean((window as any).aptrinsic)

    const identifyUser = async () => {
        let email = userInfo.email
        if (gainsightAppendNumericId) {
            email = userInfo.id + "_" + email
        }
        const id = gainsightIdPrefix + email

        const userObject = {
            id: id,
            email: email,
            loginName: userInfo.loginName,
            advertiserID: appContext.advertiserId,
            advertiserName: appContext.advertiserName,
            agencyID: userInfo.agencyId,
            agencyName: userInfo.agencyName,
            campaignID: appContext.campaignId,
            campaignName: appContext.campaignName,
            numericUserId: userInfo.id,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            role: userInfo.role.roleName,
            roleName: userInfo.role.roleName,
            roleID: userInfo.role.roleId,
            userCompany: userInfo.company,
            signUpDate: userInfo.createdTs,
            isProduction: gainsightIsProduction,
        }

        const accountObject = {
            id: gainsightIdPrefix + userInfo.agencyId,
            name: userInfo.agencyName,
            isProduction: gainsightIsProduction,
        }

        return callAptrinsic("identify", userObject, accountObject)
    }

    const trackLogin = async () => {
        return trackCustomEvent("Login", {})
    }

    const trackMenuNavigation = async (rootNode: string, path: string) =>
        trackCustomEvent("Select Menu", {
            rootNode: rootNode,
            path: path,
        })

    const trackCustomEvent = async (eventName: string, eventData: { [key: string]: any }) => {
        if (isInitialized() && !isIgnoredUser) {
            return callAptrinsic("track", eventName, eventData)
        }
    }

    const callAptrinsic = async (...args) => {
        log("Calling Aptrinsic", ...args)
        return (window as any).aptrinsic(...args)
    }

    // If initialization was requested before we had all the data, we can now proceed
    if (initializationRequested) {
        log("Initialization requested, proceeding")
        const trackLoginEvent = initializationRequested === "withLoginEvent"
        initialize(trackLoginEvent)
        setInitializationRequested(false)
    }

    // Once everything is properly initialized, we can dispatch the identify and login events
    if (isInitialized() && !isIgnoredUser) {
        let identifyUserPromise = Promise.resolve()
        if (dispatchIdentifyEvent) {
            log("Dispatching identify event")
            identifyUserPromise = identifyUser()
            setDispatchIdentifyEvent(false)
        }
        if (dispatchLoginEvent) {
            log("Dispatching login event")
            identifyUserPromise.then(trackLogin)
            setDispatchLoginEvent(false)
        }
    }

    const analyticsService: AnalyticsService = {
        initialize,
        trackMenuNavigation,
    }

    return <AnalyticsContext.Provider value={analyticsService}>{children}</AnalyticsContext.Provider>
}

export const log = (...msg) => {
    logUtil.info("[GainsightAnalyticsContextProvider] ", ...msg)
}
